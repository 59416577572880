import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}

const tableStyle = {
    borderCollapse: "collapse",
    border: "solid 4px #333"
}
const thtdStyle = {
    border: "solid 2px #333"
}
const imgStyle = {
    borderRadius: "5%"
}

const MrFaxPage = () => (
    <Layout>
        <Seo title="FAX送る君"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="mrfax">FAX送る君</h1>

            <div>
                <StaticImage src="../images/products/fax/FAX_ap3.png"
                             alt={"FAX送る君"}
                             style={imgStyle}
                             width={300}/>
            </div>
            <p></p>
            <div>
                <p>コンビニで送るより安い！シンプルなFAX送信アプリ</p>
                <p>ユーザー登録不要で Apple IDで支払いが可能です。</p>
                <p>ファイルを送るだけでなく、写真からFAX原稿を読み取れたり、送付状かんたん作成機能も付いてます。</p>
            </div>
            <hr style={hrStyle}/>

            <div>
                <StaticImage src="../images/products/fax/MrFax001.jpeg" alt={"MrFax001"} style={imgStyle}
                />
                <p></p>
                <StaticImage src="../images/products/fax/MrFax002.jpeg" alt={"MrFax002"} style={imgStyle}
                />
            </div>
            <hr style={hrStyle}/>

            {/*<div>*/}
            {/*    <h2>料金</h2>*/}
            {/*    <h3>チケット</h3>*/}
            {/*    <table style={tableStyle}>*/}
            {/*        <colgroup style={thtdStyle}>*/}
            {/*            <col width="80%"/>*/}
            {/*            <col width="20%"/>*/}
            {/*        </colgroup>*/}
            {/*        <tr style={thtdStyle}>*/}
            {/*            <td style={leftTdStyle}>送信チケット ５枚</td>*/}
            {/*            <td style={leftTdStyle}>120円</td>*/}
            {/*        </tr>*/}
            {/*        <tr style={thtdStyle}>*/}
            {/*            <td style={leftTdStyle}>送信チケット ２０枚</td>*/}
            {/*            <td style={leftTdStyle}>610円</td>*/}
            {/*        </tr>*/}
            {/*        <tr style={thtdStyle}>*/}
            {/*            <td style={leftTdStyle}>送信チケット ４５枚</td>*/}
            {/*            <td style={leftTdStyle}>1,340円</td>*/}
            {/*        </tr>*/}
            {/*    </table>*/}
            {/*</div>*/}

            {/*<hr style={hrStyle}/>*/}

            {/*<div>*/}
            {/*    <h2></h2>*/}
            {/*    <Link to="https://apple.co/3yq1lWG" style={linkStyle} rel="noreferrer noopener" target={"_blank"}>*/}
            {/*        <StaticImage src="../images/products/fax/black.svg" alt={"AppStore"}/>*/}
            {/*    </Link>*/}
            {/*</div>*/}
            {/*<hr style={hrStyle}/>*/}

            <ContactUs/>
        </div>
    </Layout>
)

export default MrFaxPage
